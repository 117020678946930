import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import { loadUser } from "./redux/actions/auth";
import PrivateRoute from "./components/routes/PrivateRoute";
import store from "./redux/store/store";
import "./App.css";
import "semantic-ui-css/semantic.min.css";

// Routes
import {
  Login,
  Register,
  Dashboard,
  Banner,
  Appointment,
  Footer,
} from "./components";

import {
  Landing,
  Services,
  Projects,
  ProjectView,
  Team,
  ContactUs,
} from "routes";

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  });
  return (
    <Provider store={store}>
      <Router>
        <Banner />
        <Appointment />
        <Switch>
          <Route exact path="/home" component={Landing} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/projects/:id" component={ProjectView} />
          <Route exact path="/contactus" component={ContactUs} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <Redirect to="/home" />
        </Switch>
        <Footer />
      </Router>
    </Provider>
  );
};

export default App;
