import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getMembers } from "reduxActions";
import Project from "./Project";
import Upload from "./Upload";

import styles from "./projects.module.scss";

const Projects = ({ members, loading, errors, getMembers, refresh }) => {
  useEffect(() => {
    getMembers();
    // eslint-disable-next-line
  }, [refresh]);
  return (
    <div className={styles.projectsContainer}>
      <div style={{ fontSize: 20, margin: 10, textAlign: "center" }}>
        New Member
      </div>
      <Upload />
      <div style={{ margin: 20, borderBottom: "2px solid white" }}></div>
      <div style={{ fontSize: 20, margin: 10, textAlign: "center" }}>
        Existing Members
      </div>
      {members &&
        members.length > 0 &&
        members.map((o) => <Project data={o} />)}
      {members && members.length === 0 && (
        <div style={{ textAlign: "center" }}>No members.</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.members.loading,
  members: state.members.members,
  errors: state.members.errors,
  refresh: state.members.refresh,
});

const mapDispatchToProps = {
  getMembers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
