import React, { useState } from "react";
import { Button, Input, TextArea, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { updateMember } from "reduxActions";

import styles from "./upload.module.scss";

const ImageUpload = ({ loading, update_errors, updateMember }) => {
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    title: "",
    description: "",
    member_rank: 999,
    avatar_link: null,
  });

  const { first_name, last_name, title, description, member_rank } = form;

  const onChange = (e) =>
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

  const onSave = () => {
    updateMember(form);
  };

  const hasErrors = update_errors && update_errors.length > 0 ? true : false;

  return (
    <div className={styles.uploadContainer}>
      <div style={{ color: "red" }}>
        {hasErrors &&
          update_errors.map((o) => (
            <div style={{ width: "100%", textAlign: "center" }}>{o.msg}</div>
          ))}
      </div>
      <Input
        inverted
        type="text"
        label="First Name"
        placeholder="First Name..."
        name="first_name"
        value={first_name}
        onChange={onChange}
        style={{ width: "100%", marginBottom: 20, minWidth: 200 }}
        maxLength="20"
        labelPosition="right"
      >
        <Label>First Name</Label>
        <input />
        <Label>{`${first_name.length}/20`}</Label>
      </Input>
      <Input
        inverted
        type="text"
        label="Last Name"
        placeholder="Last Name..."
        name="last_name"
        value={last_name}
        onChange={onChange}
        style={{ width: "100%", marginBottom: 20, minWidth: 200 }}
        maxLength="20"
        labelPosition="right"
      >
        <Label>Last Name</Label>
        <input />
        <Label>{`${last_name.length}/20`}</Label>
      </Input>
      <div />
      <Input
        inverted
        type="text"
        label="Title"
        placeholder="Title..."
        name="title"
        value={title}
        onChange={onChange}
        style={{ width: "100%", marginBottom: 20, minWidth: 200 }}
        maxLength="40"
        labelPosition="right"
      >
        <Label>Title</Label>
        <input />
        <Label>{`${title.length}/40`}</Label>
      </Input>
      <div />
      <TextArea
        inverted
        type="text"
        label="Description"
        placeholder="Description..."
        name="description"
        value={description}
        onChange={onChange}
        style={{ width: "100%", marginBottom: 20, minWidth: 200 }}
        maxLength="500"
      />
      <div />
      <Input
        inverted
        type="number"
        label="Rank/Order"
        placeholder="Rank/Order..."
        name="member_rank"
        value={member_rank}
        onChange={onChange}
        style={{ width: "100%", marginBottom: 20, minWidth: 200 }}
      />
      <div />
      <div />
      <Button
        content="Save"
        onClick={onSave}
        style={{ width: "100%", maxWidth: 200 }}
        positive
        loading={loading}
        disabled={loading}
      />
    </div>
  );
};

ImageUpload.propTypes = {
  loading: PropTypes.bool,
  updateMember: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loading: state.members.loading,
  update_errors: state.members.update_errors,
});

const mapDispatchToProps = { updateMember };

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
