import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "reduxActions";
import LogoImg from "img/logo.png";
import MobileIcon from "img/mobileicon.png";

// eslint-disable-next-line
import styles from "./styles.scss";

const uuid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

const routes = [
  { route: "", image: LogoImg, text: "PRO1" },
  { route: "/home", image: false, text: "HOME" },
  { route: "/services", image: false, text: "SERVICES" },
  { route: "/projects", image: false, text: "PROJECTS" },
  // { route: "/team", image: false, text: "TEAM" },
  { route: "/contactus", image: false, text: "CONTACT" },
];

const adminRoutes = [
  { route: "/dashboard", image: false, text: "DASHBOARD" },
  { route: "/projects", image: false, text: "PROJECTS" },
  // { route: "/team", image: false, text: "TEAM" },
  { route: "/login", image: false, text: "LOGOUT", useLogout: true },
];

const Menu = ({ isAuth, logout }) => {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setShow(false);
  }, [pathname]);
  const renderRoutes = isAuth ? adminRoutes : routes;
  const splitPath = pathname.split("/");
  const curRoute = renderRoutes.find((o) => {
    if (!o.route) return false;
    if (splitPath.length < 2) return true;
    return o.route.includes(splitPath[1]);
  });

  return (
    <div className="banner-container">
      <div className="banner-item-container">
        {renderRoutes.map((o) =>
          o.route ? (
            <div
              key={uuid()}
              className={`banner-item ${
                pathname === o.route ? "banner-active-link" : ""
              }`}
            >
              <Link
                to={`${o.route}`}
                onClick={() => (o.useLogout ? logout() : null)}
              >
                {o.text}
              </Link>
            </div>
          ) : (
            <div key={uuid()} className="banner-logo-container">
              <Link to={`/home`}>
                <img
                  className="banner-logo-img"
                  alt="Euroquipe"
                  src={o.image}
                />
              </Link>
            </div>
          )
        )}
      </div>
      <div className="banner-item-container-dropdown">
        <div className="banner-logo-dropdown">
          <Link to={`/`}>
            <img className="banner-logo-img" alt="Euroquipe" src={LogoImg} />
          </Link>
        </div>
        <div className="banner-dropdown">
          <div
            className="banner-dropdown-mobile-icon-container"
            onClick={() => setShow(!show)}
          >
            <div className="banner-mobile-current-route">
              {curRoute ? curRoute.text : "Login"}
            </div>
            <img className="banner-mobile-icon" src={MobileIcon} alt="" />
          </div>
          {show && (
            <div className="banner-dropdown-content">
              {renderRoutes
                .filter((o) => o.route)
                .map((o) => (
                  <Link
                    key={uuid()}
                    to={`${o.route}`}
                    onClick={() => {
                      if (o.useLogout) {
                        return logout();
                      }
                      setShow(false);
                    }}
                  >
                    {o.text}
                  </Link>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
