// General UI
export const SET_DASH_TAB = "SET_DASH_TAB";

// Signup and Unsub --- reducer => signup.js
export const SET_FORM = "SET_FORM";
export const SET_APPOINTMENT_LOADING = "SET_APPOINTMENT_LOADING";
export const SET_APPOINTMENT_ERRORS = "SET_APPOINTMENT_ERRORS";
export const APPOINTMENT_SUCCESS = "APPOINTMENT_SUCCESS";

// General authorization --- reducer => auth.js
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_LOADING = "SET_LOADING";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_FAIL = "REGISTER_FAIL";

// Upload files --- reducer => upload.js
export const SET_UPLOAD_TAB = "SET_UPLOAD_TAB";
export const SET_ANSWERS_FILE = "SET_ANSWERS_FILE";
export const SET_RESPONSES_FILE = "SET_RESPONSES_FILE";
export const SET_UPLOAD_LOADING = "SET_UPLOAD_LOADING";
export const UPLOAD = "UPLOAD";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_AVATAR_SUCCESS = "UPLOAD_AVATAR_SUCCESS";
export const UPLOAD_ERROR = "UPLOAD_ERROR";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const SET_DEFAULT_UPLOAD_STATE = "SET_DEFAULT_UPLOAD_STATE";

// User data --- reduce => data.js
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_SUCCESS_ERROR = "GET_PROJECTS_SUCCESS_ERROR";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_ERROR = "UPDATE_PROJECT_ERROR";

// Members
export const GET_MEMBERS = "GET_MEMBERS";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_MEMBERS_ERROR = "GET_MEMBERS_ERROR";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";
export const UPDATE_MEMBER_ERROR = "UPDATE_MEMBER_ERROR";
