import React, { useState } from "react";
import { Button, Input, TextArea, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateMember } from "reduxActions";
import Avatar from "./Avatar";
import styles from "./projects.module.scss";

const Edit = ({ data, update_errors, loading, updateMember }) => {
  const [form, setForm] = useState({
    id: data.id,
    first_name: data.first_name,
    last_name: data.last_name,
    title: data.title,
    description: data.description,
    member_rank: data.member_rank,
    avatar_link: data.avatar_link,
  });

  const onChange = (e) =>
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

  const onSave = () => {
    updateMember(form);
  };

  const onDelete = () => {
    updateMember({ ...form, deleted: 1 });
  };

  const hasErrors = update_errors && update_errors.length > 0 ? true : false;

  const { first_name, last_name, title, description, member_rank } = form;

  return (
    <>
      <Avatar memberId={data.id} data={data} />
      <div className={styles.uploadContainer}>
        <div style={{ color: "red" }}>
          {hasErrors &&
            update_errors.map((o) => (
              <div style={{ width: "100%", textAlign: "center" }}>{o.msg}</div>
            ))}
        </div>
        {}
        <Input
          inverted
          type="text"
          label="First Name"
          placeholder="First Name..."
          name="first_name"
          value={first_name}
          onChange={onChange}
          style={{ width: "100%", marginBottom: 20, minWidth: 200 }}
          maxLength="20"
          labelPosition="right"
        >
          <Label>First Name</Label>
          <input />
          <Label>{`${first_name.length}/20`}</Label>
        </Input>
        <Input
          inverted
          type="text"
          label="Last Name"
          placeholder="Last Name..."
          name="last_name"
          value={last_name}
          onChange={onChange}
          style={{ width: "100%", marginBottom: 20, minWidth: 200 }}
          maxLength="20"
          labelPosition="right"
        >
          <Label>Last Name</Label>
          <input />
          <Label>{`${last_name.length}/20`}</Label>
        </Input>
        <div />
        <Input
          inverted
          type="text"
          label="Title"
          placeholder="Title..."
          name="title"
          value={title}
          onChange={onChange}
          style={{ width: "100%", marginBottom: 20, minWidth: 200 }}
          maxLength="40"
          labelPosition="right"
        >
          <Label>Title</Label>
          <input />
          <Label>{`${title.length}/40`}</Label>
        </Input>
        <div />
        <TextArea
          inverted
          type="text"
          label="Description"
          placeholder="Description..."
          name="description"
          value={description}
          onChange={onChange}
          style={{ width: "100%", marginBottom: 20, minWidth: 200 }}
          maxLength="500"
        />
        <div />
        <Input
          inverted
          type="number"
          label="Rank/Order"
          placeholder="Rank/Order..."
          name="member_rank"
          value={member_rank}
          onChange={onChange}
          style={{ width: "100%", marginBottom: 20, minWidth: 200 }}
        />
        <div style={{ textAlign: "center" }}>
          <Button.Group>
            <Button
              content="Update"
              onClick={onSave}
              style={{ width: "100%", maxWidth: 200 }}
              positive
              loading={loading}
              disabled={loading}
            />
            <Button.Or />
            <Button
              onClick={onDelete}
              loading={loading}
              disabled={loading}
              negative
            >
              Delete
            </Button>
          </Button.Group>
        </div>
      </div>
    </>
  );
};

Edit.propTypes = {
  loading: PropTypes.bool,
  updateMember: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loading: state.members.loading,
  update_errors: state.members.update_errors,
});

const mapDispatchToProps = { updateMember };

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
