import React from "react";
import { ReactSortable } from "react-sortablejs";
import { connect } from "react-redux";
import { TextArea } from "semantic-ui-react";

import styles from "./projects.module.scss";

const Sortable = ({
  data,
  state,
  setState,
  onDelete,
  photoDescriptions,
  setPhotoDescriptions,
}) => {
  return (
    <div>
      <ReactSortable list={state} setList={setState}>
        {state
          .filter((o) => !o.deleted)
          .map((item, i) => (
            <div key={"question" + item.id} className={styles.imageContainer}>
              <div style={{ fontSize: 20, margin: 10, fontWeight: "bold" }}>
                {i + 1}
              </div>
              <div>
                <img className={styles.sortImg} src={item.url} alt="hint" />
              </div>
              <div style={{ width: "100%" }}>
                <TextArea
                  inverted
                  type="text"
                  label="Info"
                  placeholder="Description..."
                  value={photoDescriptions[item.id]}
                  onChange={(e, data) =>
                    setPhotoDescriptions({
                      ...photoDescriptions,
                      [item.id]: data.value,
                    })
                  }
                  style={{
                    width: "100%",
                    margin: "0 10px",
                    minWidth: 200,
                    minHeight: 100,
                  }}
                />
              </div>
              <div
                className={styles.deletebtn}
                hintid={item.id}
                onClick={onDelete}
              >
                X
              </div>
            </div>
          ))}
      </ReactSortable>
    </div>
  );
};

Sortable.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Sortable);
