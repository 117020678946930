import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import { setForm, appointment } from "../../redux/actions/appointment";
import { connect } from "react-redux";
import styles from "./appointment.module.scss";

const Appointment = ({ form, setForm }) => {
  return (
    <Modal
      onClose={() => setForm(false)}
      onOpen={() => setForm(true)}
      open={form}
      style={{ maxWidth: 700 }}
      closeIcon
    >
      <iframe
        src="https://app.shopmonkey.io/quote-request/a41d66f8aef307c94c6343adcf4342e2?noExternalScripts=1"
        width="100%"
        height="700"
        frameborder="0"
      ></iframe>
    </Modal>
  );
};

Appointment.propTypes = {
  form: PropTypes.bool.isRequired,
  appointment: PropTypes.func.isRequired,
  errors: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  form: state.appointment.form,
  errors: state.appointment.errors,
  loading: state.appointment.loading,
  success: state.appointment.success,
});

const mapDispatchToProps = {
  setForm,
  appointment,
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
