import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import Edit from "./Edit";
import { connect } from "react-redux";
import { updateProject } from "reduxActions";

import styles from "./projects.module.scss";

const Project = ({ data, updateProject, refresh }) => {
  const [edit, setEdit] = useState(false);
  const [state, setState] = useState(
    data.photos.map((o) => ({ ...o, deleted: 0 }))
  );
  const [name, setName] = useState(data.name);
  const [description, setDecription] = useState(data.description);
  const [photoDescriptions, setPhotoDescriptions] = useState(
    data && data.photos
      ? data.photos.reduce(
          (a, b) => ({ ...a, [b.id]: b.description ? b.description : "" }),
          {}
        )
      : []
  );

  useEffect(() => {
    const newState = data.photos.map((o) => ({ ...o, deleted: 0 }));
    setState(newState);
  }, [data]);

  const onDelete = (e) => {
    const deleteid = parseInt(e.target.getAttribute("hintid"), 10);
    const newState = state.map((o) => {
      if (o.id == deleteid) return { ...o, deleted: 1 };
      return o;
    });
    setState(newState);
  };

  const onSave = () => {
    const photo_order = state.map((o, i) => ({
      id: o.id,
      photo_order: i,
      file_status: !o.deleted,
      description: photoDescriptions[o.id],
    }));
    updateProject({ id: data.id, name, description, photo_order });
  };

  const removeProject = () => {
    if (window.confirm("This will remove the project. Press OK to confirm.")) {
      updateProject({ ...data, project_status: 0 }, true);
    }
  };

  return (
    <div className={styles.itemContainer}>
      <div className={styles.itemName}>{data.name}</div>
      <div className={styles.showHide}>
        {edit ? (
          <Button.Group>
            <Button color="orange" onClick={() => removeProject()}>
              Remove Project
            </Button>
            <Button positive onClick={() => onSave()}>
              Save
            </Button>
            <Button primary onClick={() => setEdit(!edit)}>
              Back
            </Button>
          </Button.Group>
        ) : (
          <Button.Group>
            <Button primary onClick={() => setEdit(!edit)}>
              Edit
            </Button>
          </Button.Group>
        )}
      </div>
      {edit && (
        <Edit
          data={data}
          state={state}
          setState={setState}
          onDelete={onDelete}
          name={name}
          setName={setName}
          description={description}
          setDecription={setDecription}
          onSave={onSave}
          photoDescriptions={photoDescriptions}
          setPhotoDescriptions={setPhotoDescriptions}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  updateProject,
};

export default connect(null, mapDispatchToProps)(Project);
