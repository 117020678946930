import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getProjects } from "reduxActions";
import Project from "./Project";
import Upload from "../upload/Upload";

import styles from "./projects.module.scss";

const Projects = ({ projects, loading, errors, getProjects, refresh }) => {
  useEffect(() => {
    getProjects();
    // eslint-disable-next-line
  }, [refresh]);
  return (
    <div className={styles.projectsContainer}>
      <div style={{ fontSize: 20, margin: 10, textAlign: "center" }}>
        New Project
      </div>
      <Upload />
      <div style={{ margin: 30, borderBottom: "2px solid white" }}></div>
      <div style={{ fontSize: 20, margin: 10, textAlign: "center" }}>
        Existing Projects
      </div>
      {projects &&
        projects.length > 0 &&
        projects.map((o) => (
          <Project
            key={`projectlistitem-${o.id}`}
            name={o.name}
            data={o}
            refresh={refresh}
          />
        ))}
      {projects && projects.length === 0 && (
        <div style={{ textAlign: "center" }}>No projects uploaded.</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.project.loading,
  projects: state.project.projects,
  errors: state.project.errors,
  refresh: state.upload.refresh,
});

const mapDispatchToProps = {
  getProjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
