import {
  SET_UPLOAD_TAB,
  SET_UPLOAD_LOADING,
  UPLOAD,
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  SET_UPLOAD_PROGRESS,
  SET_DEFAULT_UPLOAD_STATE,
} from "../constants/constants";

const { v4 } = require("uuid");

const initialState = {
  upload_tab: 0,
  answers_file: null,
  responses_file: null,
  loading: false,
  upload_success: false,
  upload_errors: null,
  progress_bar: 0,
  refresh: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_UPLOAD_TAB:
      return {
        ...state,
        progress_bar: payload,
      };
    case SET_UPLOAD_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case UPLOAD:
      return {
        ...state,
        upload_errors: null,
        progress_bar: 0,
        loading: true,
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        upload_success: true,
        progress_bar: 100,
        refresh: v4(),
      };
    case UPLOAD_ERROR:
      return {
        ...state,
        upload_errors: payload,
        progress_bar: 0,
        loading: false,
      };
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        progress_bar: payload,
      };
    case SET_DEFAULT_UPLOAD_STATE:
      return initialState;
    default:
      return state;
  }
}
