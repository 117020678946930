import React from "react";
import styles from "./styles.module.scss";
import { v4 } from "uuid";

import { Loader, Dimmer } from "semantic-ui-react";

const index = ({ data, loading }) => {
  return (
    <div>
      {loading && (
        <Dimmer active>
          <Loader />
        </Dimmer>
      )}
      <div className={styles["grid-container"]}>
        <div className={styles["grid"]}>
          {data.map((o) => (
            <div key={v4()} className={styles["griditem"]}>
              {o.description && (
                <div className={styles["griditem-hoverinfo"]}>
                  {o.description}
                </div>
              )}
              <div className={styles["griditem-img-container"]}>
                <img
                  className={styles["griditem-img"]}
                  alt="griditem"
                  src={o.avatar_url ? o.avatar_url : ""}
                />
              </div>
              <div className={styles["griditem-info"]}>
                {o.first_name || o.last_name ? (
                  <div className={styles["griditem-info1"]}>
                    {o.first_name} {o.last_name}
                  </div>
                ) : null}
                <div className={styles["griditem-info2"]}>{o.title}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default index;
