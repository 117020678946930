import React from "react";

// eslint-disable-next-line
import styles from "./styles.scss";

const Logos = () => {
  return (
    <div className="logos-logosContainer">
      <div className="logos-logo">AUDI</div>
      <div className="logos-logo">MERCEDES</div>
      <div className="logos-logo">BMW</div>
      <div className="logos-logo">PORSCHE</div>
      <div className="logos-logo">VOLKSWAGEN</div>
      <div className="logos-logo">SUBARU</div>
      <div className="logos-logo">MINI</div>
    </div>
  );
};

export default Logos;
