import React, { useState, useEffect } from "react";
import AvatarImageCropper from "react-avatar-image-cropper";
import { connect } from "react-redux";
import { updateAvatar } from "reduxActions";
import { Button } from "semantic-ui-react";

const Avatar = ({ data, memberId, updateAvatar, loading }) => {
  const avatar_url = data && data.avatar_url;
  const [exists, setExists] = useState(avatar_url ? true : false);
  const apply = (file) => {
    // handle the blob file you want
    // such as get the image src
    // var src = window.URL.createObjectURL(file);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("memberId", memberId);
    updateAvatar(formData);
  };

  useEffect(() => {
    if (avatar_url) {
      setExists(true);
    }
  }, [avatar_url]);

  return (
    <div
      style={{
        width: "353px",
        height: "303px",
        margin: "auto",
        border: "1px solid black",
      }}
    >
      {exists && (
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute" }}>
            <Button onClick={() => setExists(false)} color="red">
              Reset
            </Button>
          </div>
          <img src={data.avatar_url} alt="avatar_url" />
        </div>
      )}
      {!exists && <AvatarImageCropper apply={apply} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.members.loading,
});

const mapDispatchToProps = {
  updateAvatar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
