import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { setForm } from "reduxActions";
import { connect } from "react-redux";

import CoverImg from "img/cover.jpg";

// eslint-disable-next-line
import styles from "./styles.scss";

const Cover = ({ setForm }) => {
  return (
    <div className="cover-cover">
      <img className="cover-coverImg" src={CoverImg} alt="AiBC" />
      <div className="cover-centered">
        <div className="cover-logosContainer">
          <div className="cover-logo">AUDI</div>
          <div className="cover-logo">MERCEDES</div>
          <div className="cover-logo">BMW</div>
          <div className="cover-logo">PORSCHE</div>
          <div className="cover-logo">VOLKSWAGEN</div>
          <div className="cover-logo">SUBARU</div>
          <div className="cover-logo">MINI</div>
        </div>
        <div className="cover-special">
          Fox Valley's European Car Specialists
        </div>
        <div className="cover-nextSpecial">
          Give your car the attention it deserves! Schedule your appointment
          today.
        </div>
        <div className={`appointmentBtn coverBtn`}>
          <div>
            <Button
              style={{
                borderRadius: 10,
                letterSpacing: 1,
                marginTop: 20,
                backgroundColor: "#f27420",
              }}
              onClick={() => setForm(true)}
              primary
              size="huge"
            >
              Schedule Appointment
            </Button>
          </div>
          <div onClick={() => setForm(true)} className={"apnIcon"}>
            <Icon name="hand pointer" size="tiny" />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  setForm,
};

export default connect(null, mapDispatchToProps)(Cover);
