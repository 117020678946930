import { combineReducers } from "redux";
import auth from "./auth";
import ui from "./ui";
import appointment from "./appointment";
import upload from "./upload";
import project from "./project";
import members from "./members";

export default combineReducers({
  auth,
  ui,
  appointment,
  upload,
  project,
  members,
});
