import React from "react";
import { PageTitle, ContactUs } from "components";

const index = () => {
  return (
    <>
      <PageTitle title="CONTACT US" />
      <ContactUs />
    </>
  );
};

export default index;
