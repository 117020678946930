import axios from "axios";
import {
  SET_DEFAULT_UPLOAD_STATE,
  SET_UPLOAD_PROGRESS,
  UPLOAD_ERROR,
  UPLOAD_SUCCESS,
  UPLOAD,
  UPLOAD_AVATAR_SUCCESS,
} from "../constants/constants";

// Set default state
export const setDefaultUploadState = () => async (dispatch) => {
  dispatch({ type: SET_DEFAULT_UPLOAD_STATE });
};

// Set progress bar
export const setProgressBar = (payload) => async (dispatch) => {
  dispatch({ type: SET_UPLOAD_PROGRESS, payload });
};

// Upload files
export const uploadFiles =
  (name, description, files, id = null) =>
  async (dispatch) => {
    dispatch({ type: UPLOAD });
    let formData = new FormData();
    formData.append("id", id);
    formData.append("name", name);
    formData.append("description", description);
    files.forEach((o, i) => {
      formData.append(i, o);
    });
    await axios
      .post(`/api/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          dispatch({
            type: SET_UPLOAD_PROGRESS,
            payload: ((loaded / total) * 100 * 1) / 3 + 66.66,
          });
        },
      })
      .then((res) => {
        dispatch({
          type: UPLOAD_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("error", err);
        const errors = err.response.data.errors;
        dispatch({ type: UPLOAD_ERROR, payload: errors });
      });
  };

// Upload avatar
export const updateAvatar = (formData) => async (dispatch) => {
  dispatch({ type: UPLOAD });
  await axios
    .post(`/api/upload/avatar`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        dispatch({
          type: SET_UPLOAD_PROGRESS,
          payload: ((loaded / total) * 100 * 1) / 3 + 66.66,
        });
      },
    })
    .then((res) => {
      dispatch({
        type: UPLOAD_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: UPLOAD_AVATAR_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log("error", err);
      const errors = err.response.data.errors;
      dispatch({ type: UPLOAD_ERROR, payload: errors });
    });
};
