import axios from "axios";
import { SET_APPOINTMENT_LOADING, SET_APPOINTMENT_ERRORS, SET_FORM, APPOINTMENT_SUCCESS } from "../constants/constants";

// Form
export const setForm = (data) => async (dispatch) => {
  dispatch({ type: SET_FORM, payload: data });
};

// Register User
export const appointment = (formData) => async (dispatch) => {
  dispatch(setSignUpLoading(true));
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(formData);
  try {
    await axios.post("/api/appointment", body, config);
    dispatch({ type: APPOINTMENT_SUCCESS });
  } catch (err) {
    const errors = err.response.data.errors;
    dispatch({
      type: SET_APPOINTMENT_ERRORS,
      payload: errors,
    });
  }
};

export const setSignUpLoading = (data) => (dispatch) => {
  dispatch({ type: SET_APPOINTMENT_LOADING, payload: data });
};
