import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./dashboard.module.scss";

import { ProjectsPane, MembersPane } from "./components";

import { Tab } from "semantic-ui-react";

const panes = [
  {
    menuItem: "Projects",
    render: () => (
      <Tab.Pane attached={false} inverted={true}>
        <ProjectsPane />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Members",
    render: () => (
      <Tab.Pane attached={false} inverted={true}>
        <MembersPane />
      </Tab.Pane>
    ),
  },
];

const Dashboard = () => {
  return (
    <div className={styles.container}>
      <div className={styles.tabContainer}>
        <Tab menu={{ inverted: true, attached: false }} panes={panes} />
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  isAuth: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
