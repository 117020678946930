import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import Edit from "./Edit";
import { connect } from "react-redux";
import { updateProject } from "reduxActions";

import styles from "./projects.module.scss";

const Project = ({ data }) => {
  const [edit, setEdit] = useState(false);
  const { first_name, last_name } = data;

  return (
    <div className={styles.itemContainer}>
      <div className={styles.itemName}>
        {first_name} {last_name}
      </div>
      <div className={styles.showHide}>
        {edit ? (
          <Button.Group>
            <Button negative onClick={() => setEdit(!edit)}>
              Back
            </Button>
          </Button.Group>
        ) : (
          <Button.Group>
            <Button primary onClick={() => setEdit(!edit)}>
              Edit
            </Button>
          </Button.Group>
        )}
      </div>
      {edit && <Edit data={data} />}
    </div>
  );
};

const mapDispatchToProps = {
  updateProject,
};

export default connect(null, mapDispatchToProps)(Project);
