import React, { useRef, useState } from "react";
import { Button, Input, TextArea, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Sortable from "./Sortable";

import { uploadFiles } from "reduxActions";

import styles from "./projects.module.scss";

const Edit = ({
  data,
  uploadFiles,
  state,
  setState,
  onDelete,
  name,
  setName,
  description,
  setDecription,
  progress,
  loading,
  upload_errors,
  onSave,
  photoDescriptions,
  setPhotoDescriptions,
}) => {
  const inputEl = useRef(null);
  const [files, setFiles] = useState(null);

  const onButtonClick = () => {
    // `current` points to the mounted text input element
    inputEl.current.click();
  };

  const onChangeFile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let newFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]);
    }
    setFiles(newFiles);
  };

  const onUpload = () => {
    uploadFiles(name, description, files, data.id);
  };

  const genError = upload_errors && upload_errors.length > 0;

  return (
    <>
      <div className={styles.uploadContainer}>
        <Input
          labelPosition="right"
          inverted
          type="text"
          label="Name"
          placeholder="Name..."
          value={name}
          onChange={(e, data) => setName(data.value)}
          style={{ width: "100%", marginBottom: 20, minWidth: 200 }}
          maxLength="40"
        >
          <Label>Name</Label>
          <input />
          <Label>{`${name.length}/40`}</Label>
        </Input>
        <div />
        <TextArea
          inverted
          type="text"
          label="Info"
          placeholder="Description..."
          value={description}
          onChange={(e, data) => setDecription(data.value)}
          style={{
            width: "100%",
            marginBottom: 20,
            minWidth: 200,
            minHeight: 150,
          }}
        />
        <div />
        <Button
          inverted
          content="Choose Files"
          labelPosition="left"
          icon="file"
          onClick={onButtonClick}
          style={{ width: "100%", maxWidth: 200, marginBottom: 20 }}
        />
        <input
          ref={inputEl}
          type="file"
          multiple
          hidden
          onChange={onChangeFile}
        />
        {files &&
          files.map((o) => <div className={styles.fileName}>{o.name}</div>)}
        <div />
        {files && files.length > 0 && !loading && (
          <Button
            inverted
            disabled={!(files && files.length > 0)}
            content="Upload"
            labelPosition="left"
            icon="file"
            onClick={onUpload}
            style={{ width: "100%", maxWidth: 200 }}
          />
        )}
        {loading && (
          <div>
            <progress
              style={{ width: "100%", height: 50 }}
              value={progress}
              max="100"
            />
          </div>
        )}
        <div style={{ color: "red" }}>
          {genError &&
            upload_errors.map((o) => (
              <div style={{ width: "100%", textAlign: "center" }}>{o.msg}</div>
            ))}
        </div>
        <Sortable
          data={data}
          photos={data.photos}
          state={state}
          setState={setState}
          onDelete={onDelete}
          onSave={onSave}
          photoDescriptions={photoDescriptions}
          setPhotoDescriptions={setPhotoDescriptions}
        />
      </div>
    </>
  );
};

Edit.propTypes = {
  loading: PropTypes.bool,
  uploadFiles: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loading: state.upload.loading,
  progress: state.upload.progress_bar,
  upload_errors: state.upload.upload_errors,
});

const mapDispatchToProps = { uploadFiles };

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
