import React, { useEffect } from "react";
import { Grid } from "components";
import { connect } from "react-redux";

import { getMembers } from "reduxActions";

// eslint-disable-next-line
import styles from "./styles.scss";

const Members = ({ getMembers, members, loading }) => {
  useEffect(() => {
    getMembers();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="team-barContainer">
      <Grid data={members} loading={loading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  members: state.members.members,
  loading: state.members.loading,
});

const mapDispatchToProps = {
  getMembers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Members);
