import React from "react";
import { PageTitle, Logos, InfoBar2, ServicesGrid } from "components";

const Services = () => {
  return (
    <>
      <PageTitle title="SERVICES" />
      <Logos />
      <InfoBar2 />
      <ServicesGrid />
    </>
  );
};

export default Services;
