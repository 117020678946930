import React from "react";

// eslint-disable-next-line
import styles from "./styles.scss";

const InfoBar = () => {
  return (
    <div className="infobar-barContainer">
      <div className="infobar-bar">
        <div className="infobar-barText1">
          <span className="infobar-keyword2">Euroquipe</span> has been servicing
          European cars in Chicagoland since 2003
        </div>
        <div className="infobar-barText2">
          For over <span className="infobar-keyword">20 years</span>, we have
          been providing the best European Automotive Service in the Fox Valley.
          We focus on the cars we personally drive, race, and enjoy. Each
          manufacturer has its own vision of what a car should be; how it
          drives, handles, and communicates. All of us here at Euroquipe
          understand the uniqueness of each vehicle and are able to cater to its
          own specialized needs. Let us show you the Euroquipe difference!
        </div>
      </div>
    </div>
  );
};

export default InfoBar;
