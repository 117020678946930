import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getProjects } from "reduxActions";
import { useParams } from "react-router-dom";
import { Loader, Dimmer } from "semantic-ui-react";
import PhotoViewer from "components/photoviewer";

// eslint-disable-next-line
import styles from "./styles.scss";

const Projects = ({ projects, getProjects, loading }) => {
  const [open, setOpen] = useState(false);
  const [curPhoto, setCurPhoto] = useState(0);

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line
  }, []);

  const params = useParams();

  const projectId = params && params.id && parseInt(params.id, 10);

  const currProject =
    !loading &&
    params &&
    projects &&
    projects.length > 0 &&
    projects.find((o) => o.id === projectId);

  const photo1 =
    currProject.photos && currProject.photos.length > 0
      ? currProject.photos[0].url
      : null;

  const photoUrls =
    currProject.photos && currProject.photos.length > 0
      ? currProject.photos.map((o) => o.url)
      : null;

  const onPhoto = (photo_index) => {
    setCurPhoto(photo_index);
    setOpen(true);
  };

  return (
    <div className="projectsview-container">
      {open && (
        <PhotoViewer
          photoData={currProject.photos}
          photos={photoUrls}
          open={open}
          setOpen={setOpen}
          curPhoto={curPhoto}
        />
      )}
      {loading && (
        <Dimmer active>
          <Loader />
        </Dimmer>
      )}
      <div className="projectsview-grid-container">
        <div className="projectsview-grid">
          <div className="projectsview-griditem">
            <div className="projectsview-griditem-img-container">
              <img
                className="projectsview-griditem-img"
                alt="griditem"
                src={photo1 ? photo1 : ""}
                onClick={() => onPhoto(0)}
              />
            </div>
          </div>
          <div className="projectsview-projectDescription">
            <div className="projectsview-griditem-info">
              {currProject.name ? (
                <div className="projectsview-griditem-info1">
                  {currProject.name}
                </div>
              ) : null}
            </div>
            <div className="projectsview-descriptionText">
              {currProject.description}
            </div>
          </div>
        </div>
        <div className="projectsview-photosGrid">
          {currProject.photos &&
            currProject.photos.length > 0 &&
            currProject.photos.map((o, i) => {
              return (
                <div className="projectsview-photoContainer">
                  <img
                    onClick={() => onPhoto(i)}
                    className="projectsview-photoImg"
                    alt="griditem"
                    src={o.url ? o.url : ""}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.project.loading,
  projects: state.project.projects,
  errors: state.project.errors,
});

const mapDispatchToProps = {
  getProjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
