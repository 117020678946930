import React from "react";
import { Cover, InfoBar, Tri, Reviews } from "components";

const Landing = () => {
  return (
    <>
      <Cover />
      <InfoBar />
      <Tri />
      <Reviews />
    </>
  );
};

export default Landing;
