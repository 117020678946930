import React, { Component } from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { Input, Button } from "semantic-ui-react";

// eslint-disable-next-line
import styles from "./styles.scss";

class Captcha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_captcha_input: "",
    };
  }

  componentDidMount() {
    loadCaptchaEnginge(5);
  }

  doSubmit = () => {
    let user_captcha = this.state.user_captcha_input;

    if (validateCaptcha(user_captcha, false) == true) {
      this.props.onAppointment();
    } else {
      alert("Captcha Does Not Match");
    }
  };

  render() {
    const { success, setForm, loading } = this.props;
    return (
      <div className="captcha-container">
        {!success && (
          <div className="captcha-image-container">
            <Input
              value={this.state.user_captcha_input}
              onChange={(e) => {
                this.setState({
                  user_captcha_input: e.target.value,
                });
              }}
              placeholder="Enter Captcha Value"
              id="user_captcha_input"
              name="user_captcha_input"
              type="text"
            ></Input>
            <div className="captcha-image">
              <LoadCanvasTemplateNoReload />
            </div>
          </div>
        )}
        <div className="captcha-btns">
          <Button
            onClick={() => setForm(false)}
            style={{
              fontSize: 16,
              borderRadius: 0,
              letterSpacing: 1,
            }}
            negative
          >
            Close
          </Button>
          {!success && (
            <Button
              onClick={() => this.doSubmit()}
              positive
              style={{
                fontSize: 16,
                borderRadius: 0,
                letterSpacing: 1,
                marginLeft: 10,
              }}
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default Captcha;
