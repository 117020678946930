import {
  GET_MEMBERS,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_ERROR,
  UPDATE_MEMBER,
  UPDATE_MEMBER_ERROR,
  UPDATE_MEMBER_SUCCESS,
  UPLOAD_AVATAR_SUCCESS,
} from "../constants/constants";

import { v4 } from "uuid";

const initialState = {
  loading: false,
  members: [],
  errors: null,
  update_errors: null,
  refresh: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_MEMBER:
    case GET_MEMBERS:
      return {
        ...state,
        loading: true,
        update_errors: null,
        errors: null,
      };
    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        members: payload,
      };
    case UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        members: state.members.map((o) => (o.id === payload.id ? payload : o)),
        refresh: v4(),
        update_errors: null,
      };
    case UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        loading: false,
        members: state.members.map((o) => (o.id === payload.id ? payload : o)),
        refresh: v4(),
        update_errors: null,
      };
    case UPDATE_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        update_errors: payload,
      };
    case GET_MEMBERS_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    default:
      return state;
  }
}
