import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { login } from "../../redux/actions/auth";
import { Redirect } from "react-router-dom";
import { Form, Button, Icon, Popup, Input } from "semantic-ui-react";
import styles from "./auth.module.scss";
import find from "lodash/find";

const Login = ({ login, isAuth, loading, login_errors }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuth) {
    return <Redirect to={`/Dashboard`} />;
  }

  const emailError = find(login_errors, { param: "email" });
  const passwordError = find(login_errors, { param: "password" });
  const genError = !emailError && !passwordError && login_errors && login_errors.length > 0;

  return (
    <div className={styles.formContainer}>
      <div className={styles.form}>
        <div style={{ color: "red" }}>
          {genError && login_errors.map((o) => <div style={{ width: "100%", textAlign: "center" }}>{o.msg}</div>)}
        </div>
        <Form onSubmit={onSubmit}>
          <Form.Field>
            <p className={styles.inputLead}>
              Email
              {emailError && (
                <Popup
                  content={emailError.msg}
                  trigger={<Icon style={{ fontSize: 20, marginLeft: 3 }} name="info circle" />}
                />
              )}
            </p>
            <Input
              error={passwordError}
              placeholder="Email..."
              className={styles.input}
              name="email"
              onChange={onChange}
              value={email}
              size="large"
            />
          </Form.Field>
          <Form.Field>
            <p className={styles.inputLead}>
              Password
              {passwordError && (
                <Popup
                  content={passwordError.msg}
                  trigger={<Icon style={{ fontSize: 20, marginLeft: 3 }} name="info circle" />}
                />
              )}
            </p>
            <Input
              error={emailError}
              placeholder="Password..."
              className={styles.input}
              name="password"
              onChange={onChange}
              //   value={email}
              size="large"
              type="password"
            />
          </Form.Field>
          <Form.Field style={{ textAlign: "center", marginTop: 30 }}>
            <Button
              onClick={(e) => onSubmit(e)}
              style={{
                fontSize: 16,
                borderRadius: 0,
                letterSpacing: 1,
              }}
              positive
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </Form.Field>
        </Form>
      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuth: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  tab: state.ui.tab,
  loading: state.auth.loading,
  login_errors: state.auth.login_errors,
});

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
