import React, { useState } from "react";
import { Form, Button, Input, Icon, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./auth.module.scss";
import find from "lodash/find";
import { register } from "reduxActions";

const formFields = [
  {
    class: styles.formRow1,
    name: "email",
    type: "email",
    required: true,
    label: "Email",
  },
  {
    class: styles.formRow1,
    name: "password",
    type: "password",
    required: true,
    label: "Password",
  },
  {
    class: styles.formRow1,
    name: "password2",
    type: "password",
    required: true,
    label: "Repeat Password",
  },
  {
    class: styles.formRow1,
    name: "key",
    type: "text",
    required: true,
    label: "Key",
  },
];

const Register = ({ isRegister, loading, register, errors }) => {
  const [formText, setFormText] = useState({
    email: "",
    password: "",
    password2: "",
    key: "",
  });
  const onChange = (e) => {
    setFormText({ ...formText, [e.target.name]: e.target.value });
  };

  const genError = errors && errors.length > 0;

  return (
    <div className={styles.formContainer}>
      <div className={styles.form}>
        {isRegister && (
          <>
            <div className={styles.confirmation1}>Registration request received, waiting on admin approval.</div>
            <div className={styles.confirmation2}>Thank you!</div>
          </>
        )}
        {!isRegister && (
          <Form>
            <div style={{ color: "red" }}>
              {genError && errors.map((o) => <div style={{ width: "100%", textAlign: "center" }}>{o.msg}</div>)}
            </div>
            {formFields.map((o) => {
              const error = find(errors, { param: o.name });
              return (
                <div className={`${o.class} ${styles.formRowContainer}`}>
                  <p className={styles.inputLead}>
                    {o.required ? "*" : ""} {o.label}
                    {error && (
                      <Popup
                        content={error.msg}
                        trigger={
                          <Icon
                            style={{
                              color: "white",
                              fontSize: 20,
                              marginLeft: 3,
                            }}
                            name="info circle"
                          />
                        }
                      />
                    )}
                  </p>
                  <Input
                    error={error}
                    placeholder={`${o.label}...`}
                    className={styles.input}
                    name={o.name}
                    onChange={onChange}
                    value={formText[o.name]}
                    size="large"
                    style={{ padding: "0 5px" }}
                    type={o.type}
                  />
                </div>
              );
            })}
          </Form>
        )}
        {!isRegister && (
          <div class={styles.btnContainer}>
            <Button
              onClick={() => register(formText)}
              positive
              style={{
                fontSize: 16,
                borderRadius: 0,
                letterSpacing: 1,
              }}
              loading={loading}
              disabled={loading}
            >
              Register
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

Register.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  isRegister: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.auth.errors,
  loading: state.auth.loading,
  isRegister: state.auth.isRegister,
});

const mapDispatchToProps = {
  register,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
