import React from "react";
import { Icon } from "semantic-ui-react";
import ServicePageImg from "img/servicepage.jpg";

// eslint-disable-next-line
import styles from "./styles.scss";

const ServicesGrid = () => {
  return (
    <div className="servicesgrid-bar2Container">
      <div className="servicesgrid-grid">
        <div className="servicesgrid-imgContainer">
          <img src={ServicePageImg} alt="service2" />
        </div>
        <div className="servicesgrid-infocontainer">
          <div className="servicesgrid-bar2left servicesgrid-firstinfo">
            <div className="servicesgrid-tri">
              <div className="servicesgrid-triText1">
                Your vehicles are always securely stored indoors overnight, and
                we offer many conveniences such as:
              </div>
              <div className="servicesgrid-triText2">
                <ul className="servicesgrid-list">
                  <li>
                    <div>
                      <Icon name="check" size="small" color="orange" />
                    </div>
                    Overnight indoor storage of service vehicles
                  </li>
                  <li>
                    <div>
                      <Icon name="check" size="small" color="orange" />
                    </div>
                    Digital images of needed repairs
                  </li>
                  <li>
                    <div>
                      <Icon name="check" size="small" color="orange" />
                    </div>
                    Complimentary pre-warranty expiration inspections
                  </li>
                  <li>
                    <div>
                      <Icon name="check" size="small" color="orange" />
                    </div>
                    After hours drop-off
                  </li>
                  <li>
                    <div>
                      <Icon name="check" size="small" color="orange" />
                    </div>
                    Pre-purchase evaluations
                  </li>
                  <li>
                    <div>
                      <Icon name="check" size="small" color="orange" />
                    </div>
                    Local shuttle service available
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="servicesgrid-bar2left">
            <div className="servicesgrid-tri">
              <div className="servicesgrid-triText1">
                Euroquipe is proud to offer:
              </div>
              <div className="servicesgrid-triText2">
                <ul className="servicesgrid-list">
                  <li>
                    <div>
                      <Icon name="check" size="small" color="orange" />
                    </div>
                    Scheduled and preventative maintenance
                  </li>
                  <li>
                    <div>
                      <Icon name="check" size="small" color="orange" />
                    </div>
                    Oil services
                  </li>
                  <li>
                    <div>
                      <Icon name="check" size="small" color="orange" />
                    </div>
                    Emergency repairs
                  </li>
                  <li>
                    <div>
                      <Icon name="check" size="small" color="orange" />
                    </div>
                    Professional technicians with ongoing manufacturer training
                  </li>
                  <li>
                    <div>
                      <Icon name="check" size="small" color="orange" />
                    </div>
                    Extended warranty work
                  </li>
                  <li>
                    <div>
                      <Icon name="check" size="small" color="orange" />
                    </div>
                    ASE Master Certified Technicians
                  </li>
                  <li>
                    <div>
                      <Icon name="check" size="small" color="orange" />
                    </div>
                    Emissions
                  </li>
                  <li>
                    <div>
                      <Icon name="check" size="small" color="orange" />
                    </div>
                    Manufacturer specific programming and coding
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesGrid;
