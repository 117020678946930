import React from "react";
import Icon1 from "img/sss1.png";
import Icon2 from "img/sss2.PNG";
import Icon3 from "img/sss3.PNG";
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { setForm } from "reduxActions";

// eslint-disable-next-line
import styles from "./styles.scss";

const Tri = ({ setForm }) => {
  return (
    <div className="tri-triContainer">
      <div className="tri-tri">
        <div className="tri-triImage">
          <img className="tri-triIcon" src={Icon1} alt="AiBC" />
        </div>
        <div className="tri-triText1">Services</div>
        <div className="tri-triText2">
          <ul className="tri-list">
            <li>
              <div>
                <Icon name="check" size="small" color="orange" />
              </div>
              Scheduled and preventative maintenance
            </li>
            <li>
              <div>
                <Icon name="check" size="small" color="orange" />
              </div>
              Oil services
            </li>
            <li>
              <div>
                <Icon name="check" size="small" color="orange" />
              </div>
              Emergency repairs
            </li>
            <li>
              <div>
                <Icon name="check" size="small" color="orange" />
              </div>
              Professional technicians with ongoing manufacturer training
            </li>
            <li>
              <div>
                <Icon name="check" size="small" color="orange" />
              </div>
              Extended warranty work
            </li>
            <li>
              <div>
                <Icon name="check" size="small" color="orange" />
              </div>
              ASE Master Certified Technicians
            </li>
            <li>
              <div>
                <Icon name="check" size="small" color="orange" />
              </div>
              Emissions
            </li>
            <li>
              <div>
                <Icon name="check" size="small" color="orange" />
              </div>
              Manufacturer specific programming and coding
            </li>
          </ul>
        </div>
      </div>
      <div className="tri-tri">
        <div className="tri-triImage">
          <img className="tri-triIcon" src={Icon2} alt="AiBC" />
        </div>
        <div className="tri-triText1">Conveniences</div>
        <div className="tri-triText2">
          <ul className="tri-list">
            <li>
              <div>
                <Icon name="check" size="small" color="orange" />
              </div>
              Overnight indoor storage of service vehicles
            </li>
            <li>
              <div>
                <Icon name="check" size="small" color="orange" />
              </div>
              Digital images of needed repairs
            </li>
            <li>
              <div>
                <Icon name="check" size="small" color="orange" />
              </div>
              Complimentary pre-warranty expiration inspections
            </li>
            <li>
              <div>
                <Icon name="check" size="small" color="orange" />
              </div>
              After hours drop-off
            </li>
            <li>
              <div>
                <Icon name="check" size="small" color="orange" />
              </div>
              Pre-purchase evaluations
            </li>
            <li>
              <div>
                <Icon name="check" size="small" color="orange" />
              </div>
              Local shuttle service available
            </li>
          </ul>
        </div>
      </div>
      <div className="tri-tri">
        <div className="tri-triImage">
          <img className="tri-triIcon" src={Icon3} alt="AiBC" />
        </div>
        <div className="tri-triText1">Appointment</div>
        <div className="tri-triText2">
          Schedule an appointment from your phone or PC. Send us an appointment
          request with your prefered timing. Pick-up or drop-off service can
          also be scheduled, if needed.
          <div className="tri-appointmentBtn">
            <Button
              style={{
                borderRadius: 0,
                letterSpacing: 1,
                backgroundColor: "#f27420",
                borderRadius: 5,
              }}
              onClick={() => setForm(true)}
              primary
            >
              Schedule Appointment
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  setForm,
};

export default connect(null, mapDispatchToProps)(Tri);
