import React from "react";
import { PageTitle, ProjectsView } from "components";

const index = () => {
  return (
    <>
      <PageTitle title="SHOP PROJECTS" />
      <ProjectsView />
    </>
  );
};

export default index;
