import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getProjects } from "reduxActions";
import { v4 } from "uuid";
import { Link } from "react-router-dom";

// eslint-disable-next-line
import styles from "./styles.scss";

const Projects = ({ projects, getProjects }) => {
  useEffect(() => {
    getProjects();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="projects-grid-container">
      <div className="projects-grid">
        {projects.map((o) => {
          const photo1 =
            o.photos && o.photos.length > 0 ? o.photos[0].url : null;
          return (
            <Link key={v4()} to={`/projects/${o.id}`}>
              <div className="projects-griditem">
                {o.description && (
                  <div className="projects-griditem-hoverinfo">
                    {o.description}
                  </div>
                )}
                <div className="projects-griditem-img-container">
                  <img
                    className="projects-griditem-img"
                    alt="griditem"
                    src={photo1 ? photo1 : ""}
                  />
                </div>
                <div className="projects-griditem-info">
                  {o.name ? (
                    <div className="projects-griditem-info1">{o.name}</div>
                  ) : null}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.project.loading,
  projects: state.project.projects,
  errors: state.project.errors,
});

const mapDispatchToProps = {
  getProjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
