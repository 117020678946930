import {
  SET_APPOINTMENT_LOADING,
  SET_APPOINTMENT_ERRORS,
  SET_FORM,
  APPOINTMENT_SUCCESS,
} from "../constants/constants";

const initialState = {
  loading: false,
  errors: null,
  form: false,
  success: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_APPOINTMENT_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case SET_APPOINTMENT_ERRORS:
      return {
        ...state,
        loading: false,
        errors: payload,
      };

    case SET_FORM:
      return {
        ...state,
        form: payload,
        success: false,
      };
    case APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        errors: null,
      };
    default:
      return state;
  }
}
