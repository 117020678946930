import React from "react";

// eslint-disable-next-line
import styles from "./styles.scss";

const InfoBar2 = () => {
  return (
    <div className="infobar2-barContainer">
      <div className="infobar2-bar">
        <div className="infobar2-barText1">
          <span className="infobar2-keyword2">Euroquipe</span> utilizes the
          highest quality OEM or original replacement parts exclusively,
          guaranteed for 24 months or 24,000 miles.
        </div>
        <div className="infobar2-barText2">
          Euroquipe is also affiliated with a national and international network
          of automotive repair shops, allowing us to stay current with the
          latest technology.
        </div>
        <div className="infobar2-barText2">
          What makes Euroquipe unique is our diagnostic expertise and unrivaled
          service. We pride ourselves in paying close attention to your personal
          service requests and preferences.
        </div>
      </div>
    </div>
  );
};

export default InfoBar2;
