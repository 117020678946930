import React, { useState, useEffect } from "react";
import ImgsViewer from "react-images-viewer";

const PhotoViewer = ({ photoData = [], open, setOpen, curPhoto = 0 }) => {
  const [currentImage, setCurrentImage] = useState(curPhoto);

  useEffect(() => {
    setCurrentImage(curPhoto);
  }, [curPhoto]);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setOpen(false);
  };

  const gotoNext = () => {
    setCurrentImage(currentImage + 1);
  };

  const gotoPrevious = () => {
    setCurrentImage(currentImage - 1);
  };

  return (
    <div className="photoviewer-con">
      {open && (
        <ImgsViewer
          backdropCloseable={true}
          imgs={photoData.map((o) => ({ src: o.url, caption: o.description }))}
          currImg={currentImage}
          isOpen={true}
          onClickPrev={gotoPrevious}
          onClickNext={gotoNext}
          onClose={closeImageViewer}
        />
      )}
    </div>
  );
};

export default PhotoViewer;
