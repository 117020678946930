import React from "react";
import styles from "./footer.module.scss";
import { Icon } from "semantic-ui-react";

const index = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footer}>
        <div className={styles.footerText1}>
          <div>
            &copy;
            {new Date().getFullYear()} Euroquipe
          </div>
        </div>
        <div className={styles.addressContainer}>
          <div>601​​ Sidwell Ct., Suite J</div>
          <div>St. Charles, IL 60174-3415</div>
          <div>
            {" "}
            <a
              href="tel:630-377-3774"
              style={{ fontWeight: "bold", color: "white" }}
            >
              (630) 377-3774
            </a>
          </div>
          <div>Email: info@euroquipe.com</div>
          <div style={{ marginBottom: 20 }} />
          <div style={{ fontSize: 16, marginBottom: 4 }}>
            <a
              target="_blank"
              href="https://www.facebook.com/euroquipesaintcharles/"
              style={{ color: "white" }}
              rel="noopener noreferrer"
            >
              <Icon color="blue" size="large" name="facebook official" />
              euroquipestcharles
            </a>
          </div>
          <div style={{ fontSize: 16, marginBottom: 4 }}>
            <a
              target="_blank"
              href="https://www.instagram.com/euroquipe"
              style={{ color: "white" }}
              rel="noopener noreferrer"
            >
              <Icon color="purple" size="large" name="instagram" />
              euroquipe
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
