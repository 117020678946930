import React from "react";
import { useState } from "react";
import ReactSimplyCarousel from "react-simply-carousel";

// eslint-disable-next-line
import styles from "./styles.scss";

const reviewsList = [
  {
    name: "Daniel",
    review:
      "This place is amazing. If you’re looking for exceptional work, a shop you can trust and a place that is just as concerned with keeping the customer happy as they are putting out quality work this is it. There is not a single shop I would trust with my car over this one!",
  },
  {
    name: "Charles",
    review:
      "Brought a car in for a pre-purchase inspection. Honest, extremely knowledgeable and fair pricing. Every detail of the vehicle was explained thoroughly. I genuinely felt providing excellent service is a priority. I highly recommend Euroquipe and will definitely be going back in the future.",
  },
  {
    name: "Matt",
    review:
      "Jake and everyone on the team at Euroquipe are amazing. Brought my S3 to get diag after some misfire issues and they were able to take care of me and get the right solution.",
  },
  {
    name: "Neil",
    review:
      "I've taken my C7 A6 here on a few occasions now, and have been very satisfied each time. The shop is managed professionally and always very clean. They get back to you in a timely manner and everyone there is friendly.",
  },
  {
    name: "Casey",
    review:
      "Very happy with Euroquipe and their owner Adam Kosela. My audi s4 need an engine replacement, and Adam was happy to take on the job. He was very knowledgeable and patient in answering all of the questions I had.  He took the time to source a good replacement motor and do a inspection on it before putting it in my Audi. Car is still running very strong since the heart transplant! I plan to bring any other work back to Adam and his team!",
  },
  {
    name: "Anna",
    review:
      "I took my car into the shop this past week and had a great experience. Professional and truly cared about his work. Would def recommend!!",
  },
];

const InfoBar = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div className="infobar-barContainer-reviews">
      <div className="infobar-bar-reviews">
        <ReactSimplyCarousel
          activeSlideIndex={activeSlideIndex}
          onRequestChange={setActiveSlideIndex}
          itemsToShow={3}
          itemsToScroll={1}
          forwardBtnProps={{
            //here you can also pass className, or any other button element attributes
            style: {
              alignSelf: "center",
              background: "black",
              border: "none",
              borderRadius: "50%",
              color: "white",
              cursor: "pointer",
              fontSize: "20px",
              height: 30,
              lineHeight: 1,
              textAlign: "center",
              width: 30,
            },
            children: <span>{`>`}</span>,
          }}
          backwardBtnProps={{
            //here you can also pass className, or any other button element attributes
            style: {
              alignSelf: "center",
              background: "black",
              border: "none",
              borderRadius: "50%",
              color: "white",
              cursor: "pointer",
              fontSize: "20px",
              height: 30,
              lineHeight: 1,
              textAlign: "center",
              width: 30,
            },
            children: <span>{`<`}</span>,
          }}
          responsiveProps={[
            { minWidth: 1200, maxWidth: 1400, itemsToShow: 3 },
            { minWidth: 1001, maxWidth: 1199, itemsToShow: 2 },
            { maxWidth: 1000, itemsToShow: 1 },
          ]}
          speed={400}
          easing="linear"
        >
          {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
          {reviewsList.map((r) => (
            <div
              style={{
                width: 350,
                padding: 20,
                fontSize: 16,
                color: "white",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div style={{ fontStyle: "italic" }}>"{r.review}"</div>
                <div style={{ margin: "10px 0 0" }}>- {r.name}</div>
              </div>
            </div>
          ))}
        </ReactSimplyCarousel>
      </div>
    </div>
  );
};

export default InfoBar;
