import React from "react";
import { Icon, Button } from "semantic-ui-react";
import { setForm } from "reduxActions";
import { connect } from "react-redux";

// eslint-disable-next-line
import styles from "./styles.scss";

const ContactUs = ({ setForm }) => {
  return (
    <div className="barContainer">
      <div className="allday">24/7 drop off/pick up Available</div>
      <div className="bar">
        <div className="left">
          601​​ Sidwell Ct., Suite J <br />
          St. Charles, IL 60174-3415 <br />
          <a href="tel:630-377-3774" style={{ fontWeight: "bold" }}>
            (630) 377-3774
          </a>{" "}
          <br />
          Email: info@euroquipe.com
        </div>
        <div className="right">
          <span className="leadTime">Monday</span> 8:00 - 5:00
          <br />
          <span className="leadTime">Tuesday</span> 8:00 - 5:00
          <br />
          <span className="leadTime">Wednesday</span> 8:00 - 5:00
          <br />
          <span className="leadTime">Thursday</span> 8:00 - 5:00
          <br />
          <span className="leadTime">Friday</span> 8:00 - 5:00
        </div>
      </div>
      <div className="social">
        <div style={{ fontSize: 16, marginBottom: 4 }}>
          <a
            target="_blank"
            href="https://www.facebook.com/euroquipesaintcharles/"
            style={{ color: "white" }}
            rel="noopener noreferrer"
          >
            <Icon color="blue" size="large" name="facebook official" />
            euroquipestcharles
          </a>
        </div>
        <div style={{ fontSize: 16, marginBottom: 4 }}>
          <a
            target="_blank"
            href="https://www.instagram.com/euroquipe"
            style={{ color: "white" }}
            rel="noopener noreferrer"
          >
            <Icon color="purple" size="large" name="instagram" />
            euroquipe
          </a>
        </div>
      </div>
      <div className="appointmentBtncoverBtn">
        <Button
          style={{
            borderRadius: 10,
            letterSpacing: 1,
            marginTop: 20,
            backgroundColor: "#f27420",
          }}
          onClick={() => setForm(true)}
          primary
          size="huge"
        >
          Schedule Appointment
        </Button>
      </div>
      <div className="gmap_canvas">
        <iframe
          title="map"
          className="iframe"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=601%E2%80%8B%E2%80%8B%20Sidwell%20Ct.,%20Suite%20J%20St.%20Charles,%20IL%2060174-3415&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  setForm,
};

export default connect(null, mapDispatchToProps)(ContactUs);
