import React from "react";
import { PageTitle, Projects } from "components";

const ShopProjects = () => {
  return (
    <>
      <PageTitle title="SHOP PROJECTS" />
      <Projects />
    </>
  );
};

export default ShopProjects;
