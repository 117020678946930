import axios from "axios";
import {
  GET_MEMBERS,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_ERROR,
  UPDATE_MEMBER,
  UPDATE_MEMBER_ERROR,
  UPDATE_MEMBER_SUCCESS,
} from "../constants/constants";

// Form
export const getMembers = () => async (dispatch) => {
  dispatch({ type: GET_MEMBERS });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get("/api/member", config);
    dispatch({ type: GET_MEMBERS_SUCCESS, payload: res.data });
  } catch (err) {
    const errors = err.response.data.errors;
    dispatch({
      type: GET_MEMBERS_ERROR,
      payload: errors,
    });
  }
};

// Update project
export const updateMember = (body) => async (dispatch) => {
  dispatch({ type: UPDATE_MEMBER });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post("/api/member/update", body, config);
    dispatch({ type: UPDATE_MEMBER_SUCCESS, payload: res.data });
  } catch (err) {
    const errors = err.response.data.errors;
    dispatch({
      type: UPDATE_MEMBER_ERROR,
      payload: errors,
    });
  }
};
