import {
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_SUCCESS_ERROR,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
} from "../constants/constants";

const initialState = {
  loading: false,
  projects: [],
  errors: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PROJECT:
    case GET_PROJECTS:
      return {
        ...state,
        loading: payload,
      };
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: payload,
      };
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: state.projects.map((o) => (o.id === payload.id ? payload : o)),
      };
    case GET_PROJECTS_SUCCESS_ERROR:
    case UPDATE_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    default:
      return state;
  }
}
