import axios from "axios";
import {
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_SUCCESS_ERROR,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
} from "../constants/constants";

// Form
export const getProjects = () => async (dispatch) => {
  dispatch({ type: GET_PROJECTS });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get("/api/project", config);
    dispatch({ type: GET_PROJECTS_SUCCESS, payload: res.data });
  } catch (err) {
    const errors = err.response.data.errors;
    dispatch({
      type: GET_PROJECTS_SUCCESS_ERROR,
      payload: errors,
    });
  }
};

// Update project
export const updateProject = (body, refresh) => async (dispatch) => {
  dispatch({ type: UPDATE_PROJECT });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post("/api/project/update", body, config);
    dispatch({ type: UPDATE_PROJECT_SUCCESS, payload: res.data });
    if (refresh) dispatch(getProjects());
  } catch (err) {
    const errors = err.response.data.errors;
    dispatch({
      type: UPDATE_PROJECT_ERROR,
      payload: errors,
    });
  }
};
