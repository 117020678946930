import React from "react";
import CoverImg from "img/cover.jpg";

// eslint-disable-next-line
import styles from "./styles.scss";

const PageTitle = ({ title, image }) => {
  return (
    <div className="pagetitle-container">
      <div className="pagetitle-cover">
        <img
          className="pagetitle-coverImg"
          src={image ? image : CoverImg}
          alt="AiBC"
        />
        <div className="pagetitle-centered">{title}</div>
      </div>
    </div>
  );
};

export default PageTitle;
